<template>
  <div class="index">
    <navigation msg=""></navigation>
    <div class="content" id="pdf">
      <div class="title">创建岗位胜任力模型</div>
      <div class="label">招聘岗位名称</div>
      <div class="name">{{ form.job_nickname }}</div>
      <div class="label">选择岗位类型</div>
      <div class="name">{{ form.job }}</div>
      <div class="label">招聘人数（最小值1）</div>
      <div class="name">{{ form.recruit_number }}</div>
      <div class="label">工作年限</div>
      <div class="name">{{ job_status(form.years_working) }}</div>
      <div class="label">最低学历要求</div>
      <div class="name">{{ form.education_background }}</div>
      <div class="label">院校要求</div>
      <div class="name">{{ form.academy_demand }}</div>
      <div class="label">合同签署几年</div>
      <div class="name">{{ contract_time_status(form.contract_time) }}</div>
      <div class="label">试用期时间</div>
      <div class="name">
        {{ probation_period_status(form.probation_period) }}
      </div>
      <div class="label">试用期薪资折扣情况</div>
      <div class="name">
        {{ form.probation_rebate == 100 ? "无折扣" : "有折扣" }}
      </div>
      <div class="label">试用期薪资折扣详细</div>
      <div class="name">{{ form.probation_rebate }}%</div>
      <div class="label">岗位描述</div>
      <div class="name">
        {{ form.post_desc }}
      </div>
      <div class="label">任职要求</div>
      <div class="name">
        {{ form.job_require }}
      </div>
      <div class="label">有限考虑范围（对外发布）</div>
      <div class="name">
        {{ form.priority_area }}
      </div>
      <div class="label">求职者自带本岗位以外的工作技能</div>
      <div
        class="name"
        v-for="(item, index) in form.skills_required"
        :key="index"
      >
        {{ item.title }}
      </div>
      <div class="label">证书详细信息</div>
      <div
        class="name"
        v-for="(item, index) in form.demand_certificate_name"
        :key="index"
      >
        {{ item.title }}
      </div>
      <div class="label">岗位所需证书要求</div>
      <div class="name">{{ form.demand_certificate }}</div>
      <div class="label">对应聘者创业经历要求</div>
      <div class="name">{{ form.is_sartup_experience }}</div>
      <div class="label">目标岗位不考虑范围（对外发布）</div>
      <div class="name">
        {{ form.priority_range }}
      </div>
      <div class="label">不考虑范围（不对外发布）</div>
      <div class="name">
        {{ form.leave_out }}
      </div>
      <div class="label">目标岗位招聘理由</div>
      <div class="name">{{ reason_status(form.reason) }}</div>

      <div class="label">岗位所属部门信息</div>
      <div class="name">
        是否带过团队：{{ form.is_team == 1 ? "是" : "否" }}
      </div>
      <div class="name">部门名称：{{ form.department }}</div>
      <div class="name">汇报对象职务：{{ form.report_obj }}</div>
      <div class="label">岗位管理情况</div>
      <div class="name">{{ post_management_status(form.post_management) }}</div>
      <div class="label">岗位部门培训</div>
      <div class="name">{{ form.department_train }}</div>
      <div class="label">住宿提供情况</div>
      <div class="name">{{ is_dorm_status(form.is_dorm) }}</div>
      <div class="label">岗位职业发展规划</div>
      <div class="name">{{ form.job_develop }}</div>
      <div class="name">{{ form.job_develop2 }}</div>
      <div class="name">{{ form.job_develop3 }}</div>
      <div class="label">岗位培训学习方案</div>
      <div
        class="name"
        v-for="(item, index) in form.job_study_scheme"
        :key="index"
      >
        {{ item.title }}
      </div>
      <div class="label">其他备注</div>
      <div class="name">{{ form.other_note }}</div>
      <div style="height: 1rem"></div>
      <div class="title">交付服务情况</div>
      <div class="label">企业面试联系人称呼</div>
      <div class="name">{{ form.interviewer }}</div>
      <div class="label">面试联系人手机号</div>
      <div class="name">{{ form.phone }}</div>
      <div class="label">面试联系人（备选）</div>
      <div class="name">{{ form.see_man_standby }}</div>
      <div class="label">面试联系人手机号（备选）</div>
      <div class="name">{{ form.see_man_standby_tel }}</div>
      <div class="label">招聘联系邮箱</div>
      <div class="name">{{ form.email }}</div>

      <div class="label">面试地区</div>
      <div class="name">{{ form.company_address }}</div>
      <div class="label">面试详细地区</div>
      <div class="name">{{ form.detailed_address }}</div>
      <div class="label">面试地点与工作地点是否相同</div>
      <div class="name">{{ form.work_areas ? "不相同" : "相同" }}</div>
      <div class="label" v-if="form.work_areas">工作详细地址</div>
      <div class="name" v-if="form.work_areas">{{ form.work_areas }}</div>
      <div class="label">工作地点繁荣度情况</div>
      <div class="name">
        {{
          form.prosperity == 1 ? "属于城市核心行政区" : "属于非城市核心行政区"
        }}
      </div>
      <div style="height: 1rem"></div>
      <div class="title">面试信息情况及招聘服务说明</div>
      <div class="label">是否委托萝卜猎手进行初筛</div>
      <div class="name">
        {{ form.is_lbls_interview == 1 ? "委托" : "不委托" }}
      </div>
      <div class="label">面试轮次详情</div>
      <div v-for="(item, index) in positionList" :key="index">
        <div class="name">面试职位：{{ item.job }}</div>
        <div class="name">
          面试轮次：{{
            item.type == 1
              ? "电话面试"
              : item.type == 2
              ? "现场面试"
              : item.type == 3
              ? "视频面试"
              : ""
          }}
        </div>
      </div>

      <div class="label">招聘服务说明</div>
      <div class="name" v-if="form.post_suitable == 1">人才分析推荐报告</div>
      <div class="name" v-if="form.backgroud_report == 1">背景调查报告</div>
      <div class="label">招聘岗位推荐时常建议</div>
      <div class="name">{{ form.expire == 1 ? "30天" : "60天" }}</div>
      <div class="label">目标岗位招聘完成形式</div>
      <div class="name">
        {{ form.service_content == 1 ? "入职成功" : "入职过保" }}
      </div>
      <div class="label" v-if="form.service_content == 2">入职过保期限</div>
      <div class="name" v-if="form.service_content == 2">
        {{
          form.guarantee_time == 1
            ? "7天"
            : form.guarantee_time == 2
            ? "14天"
            : form.guarantee_time == 3
            ? "30天"
            : ""
        }}
      </div>
      <div style="height: 1rem"></div>
      <div class="title">岗位薪资待遇</div>
      <div class="label">薪资组成结构</div>
      <div class="name">
        <div class="list">
          <div class="item" v-if="form.salary_constitute.indexOf('工资') != -1">
            工资
          </div>
          <div class="item" v-if="form.salary_constitute.indexOf('五险') != -1">
            五险
          </div>
          <div class="item" v-if="form.salary_constitute.indexOf('一金') != -1">
            一金
          </div>
        </div>
      </div>
      <div class="name" v-if="form.salary_constitute.indexOf('五险') != -1">
        五险比例： 五险 = 工资 *
        {{ form.insurance_fund.guaranteeValue1 }}%
      </div>
      <div class="name" v-if="form.salary_constitute.indexOf('一金') != -1">
        一金比例： 一金 = 工资 *
        {{ form.insurance_fund.guaranteeValue2 }}% *
        {{ form.insurance_fund.guaranteeValue3 }}%
      </div>
      <div class="label">奖金</div>
      <div class="name">
        <div class="list">
          <div
            class="item"
            v-if="form.salary_constitute.indexOf('季度奖') != -1"
          >
            季度奖
          </div>
          <div
            class="item"
            v-if="form.salary_constitute.indexOf('年终奖') != -1"
          >
            年终奖
          </div>
          <div
            class="item"
            v-if="form.salary_constitute.indexOf('其它金额') != -1"
          >
            其它金额
          </div>
        </div>
      </div>
      <div class="name" v-if="form.salary_constitute.indexOf('年终奖') != -1">
        年终奖比例：{{
          form.year_end_bonus_ratio == 0
            ? "平均幅度高于年薪10%"
            : form.year_end_bonus_ratio == 1
            ? "平均幅度高于年薪20%"
            : form.year_end_bonus_ratio == 2
            ? "平均幅度高于年薪20%"
            : ""
        }}
      </div>
      <div class="name" v-if="form.salary_constitute.indexOf('其他奖金') != -1">
        其他奖金：{{ form.other_bonuses }}
      </div>
      <div class="label">绩效考核</div>
      <div class="name">
        <div class="list">
          <div class="item" v-if="form.salary_constitute.indexOf('绩效') != -1">
            绩效
          </div>
          <div
            class="item"
            v-if="form.salary_constitute.indexOf('团队绩效') != -1"
          >
            团队绩效
          </div>
          <div
            class="item"
            v-if="form.salary_constitute.indexOf('专业培训') != -1"
          >
            专业培训
          </div>
        </div>
      </div>
      <div class="label">福利待遇</div>
      <div class="name">
        <div class="list">
          <div
            class="item"
            v-for="(item, index) in form.welfare_treatment.split(',')"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="label">五险一金缴纳模式</div>
      <div class="name">
        {{ form.payment_mode == 1 ? "按照全额工资缴纳" : "不按照全额工资缴纳" }}
      </div>
      <div class="label">其它补助项目情况</div>
      <div v-for="(item, index) in other_subsidy_projects" :key="index">
        <div class="name">时间单位：{{ item.n1 }}</div>
        <div class="name">补助内容：{{ item.n2 }}</div>
        <div class="name">金额（RMB）：{{ item.n3 }}</div>
      </div>

      <div class="label">其它福利项目情况</div>
      <div v-for="(item, index) in other_welfare_programs" :key="index">
        <div class="name">时间单位：{{ item.n1 }}</div>
        <div class="name">补助内容：{{ item.n2 }}</div>
        <div class="name">金额（RMB）：{{ item.n3 }}</div>
      </div>
      <div style="height: 1rem"></div>
      <div class="title">岗位薪资范围</div>
      <div class="label">薪资范围起薪点</div>
      <div class="name">{{ form.salary_min }}</div>
      <div class="label">薪资范围最高点</div>
      <div class="name">{{ form.salary_max }}</div>
      <div class="label">薪资范围（发薪月数）</div>
      <div class="name">{{ form.salary_number }}</div>
      <div class="label">
        企业最高可提供薪资 <span>（针对特别优秀人才）</span>
      </div>
      <div class="name">{{ form.highest_salary }}</div>
      <div class="label">目标岗位招聘人数（最小值）</div>
      <div class="name">{{ form.recruit_number }}</div>
      <div class="label">招聘服务费（单人）（最小值500）</div>
      <div class="name">{{ form.basics }}</div>
      <div style="height: 1rem"></div>
      <div class="title">岗位报价</div>
      <div class="label">本岗位招聘报价总金额</div>
      <div class="name">{{ form.post_price }}</div>
      <div style="height: 1rem"></div>
      <div class="title">岗位发布</div>
      <div class="label">岗位发布</div>
      <div class="name">岗位服务费：{{ form.post_price }}</div>
      <div class="name">岗位生成时间：{{ time }}</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import JSPDF from "jspdf";
import html2Canvas from "html2canvas";
export default {
  data() {
    return {
      catalogueIdx: 0,
      res: {},
      positionList: [],
      form: {},
      other_subsidy_projects: "",
      other_welfare_programs: "",
      time: "",
    };
  },
  mounted() {
    if (this.$route.query.xz == 2) {
      this.$toast.loading({
        message: "下载报告中...",
        forbidClick: true,
      });
      setTimeout(() => {
        this.printOut();
      }, 2000);
    }
    this.time = new Date(+new Date() + 8 * 3600 * 1000)
      .toJSON()
      .substr(0, 10)
      .replace("T", " ");
    this.form = this.forms;
    if (this.form.interview_rounds) {
      let arr = [];
      let arr1 = [];
      let arr2 = [];
      if (this.form.interview_man) {
        arr = this.form.interview_man.split(",");
      }
      if (this.form.interview_type) {
        arr1 = this.form.interview_type.split(",");
      }

      for (let i = 0; i < this.form.interview_rounds; i++) {
        let obj1 = {
          job: arr[i] ? arr[i] : "",
          type: arr1[i] ? arr1[i] : "2",
        };
        arr2.push(obj1);
      }
      this.positionList = arr2;
      if (this.form.other_subsidy_projects != undefined) {
        this.other_subsidy_projects = [];
      }
      if (this.form.other_welfare_programs != undefined) {
        this.other_welfare_programs = [];
      }
      this.form.other_subsidy_projects = this.form.other_subsidy_projects
        ? this.form.other_subsidy_projects
        : "";
      this.form.other_welfare_programs = this.form.other_welfare_programs
        ? this.form.other_welfare_programs
        : "";
      this.form.other_subsidy_projects.split(";").forEach((item, index) => {
        if (this.form.other_subsidy_projects.split(";").length - 1 != index) {
          let arr = item.split(",");
          this.other_subsidy_projects.push({
            n1: arr[0],
            n2: arr[1],
            n3: arr[2],
          });
        }
      });
      this.form.other_welfare_programs.split(";").forEach((item, index) => {
        if (this.form.other_welfare_programs.split(";").length - 1 != index) {
          let arr = item.split(",");
          this.other_welfare_programs.push({
            n1: arr[0],
            n2: arr[1],
            n3: arr[2],
          });
        }
      });
    }
    console.log(this.form, "this.form");
  },
  methods: {
    printOut1() {
      // let pdfDom = document.querySelector("#pdf"); //这里绑定你要转换的页面
      var pdfContent = document.querySelector("#pdf");
      var width = pdfContent.offsetWidth; //获取dom 宽度
      var height = pdfContent.offsetHeight; //获取dom 高度
      var canvas = document.createElement("canvas"); //创建一个canvas节点
      var scale = 3; //定义任意放大倍数 支持小数，越大越清晰
      var offsetTop = pdfContent.offsetTop;
      var rect = pdfContent.getBoundingClientRect(); //获取元素相对于视察的偏移量
      canvas.width = width * scale; //定义canvas 宽度 * 缩放
      canvas.height = (height + offsetTop) * scale; //定义canvas（高度 +偏移量 ）*缩放
      var content = canvas.getContext("2d");
      content.scale(scale, scale); //获取context,设置scale
      content.translate(-rect.left, -rect.top); //设置context位置，值为相对于视窗的偏移量负值，让图片复位
      var opts = {
        background: "#fff",
        scale: scale, // 添加的scale 参数
        canvas: canvas, //自定义 canvas
        // logging: true, //日志开关，便于查看html2canvas的内部执行流程
        width: width, //dom 原始宽度
        height: height,
        useCORS: true, // 【重要】开启跨域配置
        allowTaint: true,
        taintTest: false,
      };
      html2Canvas(pdfContent, opts).then(function (canvas) {
        downloadCanvasWithMargin(canvas);
      });
      //-----------------------
      function downloadCanvasWithMargin(canvas) {
        //关闭锯齿
        var context = canvas.getContext("2d");
        context.mozImageSmoothingEnabled = false;
        context.webkitImageSmoothingEnabled = false;
        context.msImageSmoothingEnabled = false;
        context.imageSmoothingEnabled = false;

        let contentWidth = canvas.width;
        let contentHeight = canvas.height;
        //一页pdf显示html页面生成的canvas高度;
        let pageHeight = (contentWidth / 595.28) * 841.89;
        //未生成pdf的html页面高度
        let leftHeight = contentHeight;
        let cutStartHeight = 0;
        let canvasArr = [];
        if (leftHeight > pageHeight) {
          while (leftHeight > 0) {
            //复制出(0, cutStartHeight)开始，contentWidth宽和pageHeight高的图片
            let cutImage = context.getImageData(
              0,
              cutStartHeight,
              contentWidth,
              pageHeight
            );
            //从图像底部开始校验颜色，直到验证到以白色结尾才开始切图像
            let moveUpPx = 0; //上移px数
            let totalLength = cutImage.data.length;
            let lineLength = 4 * contentWidth * 1; //一行的data容量
            let leftLength = totalLength - lineLength;
            let rightLength = totalLength;
            let circle = 0; //循环次数
            let pointRatio = 0.98; //一行给定颜色的占比系数
            let pointRatioNum = contentWidth * pointRatio;
            while (circle === moveUpPx) {
              circle++;
              let ignorePoint = 0;
              for (let i = leftLength; i < rightLength; i += 4) {
                if (cutImage.data[i] === 255 || cutImage.data[i] === 251) {
                  if (
                    cutImage.data[i] === cutImage.data[i + 1] &&
                    cutImage.data[i] === cutImage.data[i + 2]
                  ) {
                    ignorePoint++;
                  }
                }
              }
              if (ignorePoint < pointRatioNum) {
                leftLength -= lineLength;
                rightLength -= lineLength;
                moveUpPx++;
              }
            }
            if (moveUpPx > 0) {
              cutImage = context.getImageData(
                0,
                cutStartHeight,
                contentWidth,
                pageHeight - moveUpPx
              );
            }
            leftHeight -= pageHeight - moveUpPx;
            cutStartHeight += pageHeight - moveUpPx;
            //创建新图片
            let nCanvas = document.createElement("canvas"); //创建一个canvas节点
            nCanvas.width = canvas.width;
            nCanvas.height = pageHeight - moveUpPx;
            let nContext = nCanvas.getContext("2d");
            nContext.mozImageSmoothingEnabled = false;
            nContext.webkitImageSmoothingEnabled = false;
            nContext.msImageSmoothingEnabled = false;
            nContext.imageSmoothingEnabled = false;
            nContext.putImageData(cutImage, 0, 0);
            canvasArr.push(nCanvas);
          }
        } else {
          canvasArr.push(canvas);
        }
        //处理尾页图片透明背景
        let lastCanvas = canvasArr[canvasArr.length - 1];
        let ctx = lastCanvas.getContext("2d");
        // 将canvas的透明背景设置成白色--解决translate后便黑色背景的问题
        let imageData = ctx.getImageData(
          0,
          0,
          lastCanvas.width,
          lastCanvas.height
        );
        for (var i = 0; i < imageData.data.length; i += 4) {
          // 当该像素是透明的，则设置成白色
          if (imageData.data[i + 3] === 0) {
            imageData.data[i] = 255;
            imageData.data[i + 1] = 255;
            imageData.data[i + 2] = 255;
            imageData.data[i + 3] = 255;
          }
        }
        ctx.putImageData(imageData, 0, 0);

        var PDF = new JSPDF("", "pt", "a4");
        let leftMargin = 20;
        let topMargin = 20;
        let a4Width = 595.28;
        let a4Height = 841.89;
        let imgWidth = a4Width - leftMargin * 2;
        let imgHeight = a4Height - 2 * topMargin;
        for (let i = 0; i < canvasArr.length; i++) {
          let curCanvas = canvasArr[i];
          let pageData = curCanvas.toDataURL("image/jpeg", 1.0);
          //小于切分的高度，要换算比例高
          if (curCanvas.height < pageHeight) {
            let ratioHeight = (imgWidth / curCanvas.width) * curCanvas.height;
            PDF.addImage(
              pageData,
              "JPEG",
              leftMargin,
              topMargin,
              imgWidth,
              ratioHeight
            );
          } else {
            PDF.addImage(
              pageData,
              "JPEG",
              leftMargin,
              topMargin,
              imgWidth,
              imgHeight
            );
          }
          if (i < canvasArr.length - 1) {
            PDF.addPage();
          }
        }
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let milliSeconds = date.getMilliseconds();
        PDF.save(year + month + day + milliSeconds + ".pdf");
      }
    },
    printOut() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      let pdfDom = document.querySelector("#pdf"); //这里绑定你要转换的页面
      // pdfDom.style.background = "#FFFFFF";
      let _this = this;
      let width = pdfDom.offsetWidth;
      let height = pdfDom.offsetHeight;
      // console.log('内容宽度' + width,'内容高度' + height);

      setTimeout(() => {
        html2Canvas(pdfDom, {
          //开始截图,这里可以设置一些截图时的属性
          // scale: window.devicePixelRatio,
          scale: 2,
          // scale: 2,
          logging: true,
          width: width,
          height: height,
          y: 0,
          // useCORS: true
        }).then((canvas) => {
          // console.log(canvas);
          let context = canvas.getContext("2d");
          context.mozImageSmoothingEnabled = false;
          context.webkitImageSmoothingEnabled = false;
          context.msImageSmoothingEnabled = false;
          context.imageSmoothingEnabled = false;

          let contentWidth = canvas.width;
          let contentHeight = canvas.height;
          let pageHeight = (contentWidth / 595.28) * 841.89;
          let leftHeight = contentHeight;
          let position = 0;
          let imgWidth = 595.28;
          let imgHeight = (592.28 / contentWidth) * contentHeight;

          let pageData = canvas.toDataURL("image/jpeg", 1.0);
          // console.log('绘制面板宽' + contentWidth,'绘制面板高' + contentHeight,pageData);

          let PDF = new JSPDF("", "pt", "a4");
          if (leftHeight < pageHeight) {
            PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
          } else {
            while (leftHeight > 0) {
              PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
              leftHeight -= pageHeight;
              position -= 841.89;
              if (leftHeight > 0) {
                PDF.addPage();
              }
            }
          }
          PDF.save("岗位报告.pdf");
          return;
        });
      }, 1000);
    },
    job_status(type) {
      let arr = "";
      switch (type) {
        case 0:
          arr = "无年限要求";
          break;
        case 1:
          arr = "1年以内";
          break;
        case 2:
          arr = "1-3年";
          break;
        case 3:
          arr = "3-5年";
          break;
        case 4:
          arr = "5-10年";
          break;
        case 5:
          arr = "10年以上";
          break;
        default:
          break;
      }
      return arr;
    },
    contract_time_status(type) {
      let arr = "";
      switch (type) {
        case 1:
          arr = "1年";
          break;
        case 2:
          arr = "2年";
          break;
        case 3:
          arr = "3年";
          break;
        case 0:
          arr = "3年以上";
          break;

        default:
          break;
      }
      return arr;
    },
    probation_period_status(type) {
      let arr = "";
      switch (type) {
        case 1:
          arr = "1月";
          break;
        case 2:
          arr = "2月";
          break;
        case 3:
          arr = "3月";
          break;
        case 4:
          arr = "4月";
          break;
        case 6:
          arr = "6月";
          break;
        case 0:
          arr = "无试用期";
          break;
        default:
          break;
      }
      return arr;
    },
    reason_status(type) {
      let arr = "";
      switch (type) {
        case 0:
          arr = "岗位扩张";
          break;
        case 1:
          arr = "新增岗位";
          break;
        case 2:
          arr = "人员优化";
          break;
        case 3:
          arr = "离职替补";
          break;
        case 4:
          arr = "其他";
          break;
        default:
          break;
      }
      return arr;
    },
    post_management_status(type) {
      let arr = "";
      switch (type) {
        case 1:
          arr = "非管理岗/无下属团队（无管理能力要求）";
          break;
        case 2:
          arr = "有直属管理团队（有管理要求）";
          break;
        case 3:
          arr = "目标岗位有协助管理职责（有协助管理经验要求）";
          break;
        default:
          break;
      }
      return arr;
    },
    is_dorm_status(type) {
      let arr = "";
      switch (type) {
        case 0:
          arr = "不提供住宿";
          break;
        case 1:
          arr = "提供宿舍";
          break;
        case 2:
          arr = "提供单人公寓";
          break;
        case 3:
          arr = "提供多间公寓";
          break;
        case 4:
          arr = "提供专家楼";
          break;
        case 5:
          arr = "提供其他住宿方式";
          break;
        default:
          break;
      }
      return arr;
    },
  },
  computed: {
    // 引入vuex中存储的数据
    ...mapState({
      forms: (state) => state.form,
    }),
  },
};
</script>

<style lang='scss' scoped>
.content {
  padding: 0.36rem 0.4rem;
  background-color: #fff;

  .title {
    position: relative;
    font-size: 0.3rem;
    height: 0.6rem;
    line-height: 0.45rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ff3b24;
    &::before {
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      width: 0.28rem;
      height: 0.1rem;
      background: #ff3b24;
      border-radius: 0.02rem;
    }
  }
  .label {
    margin: 0.35rem 0 0.29rem 0;
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    span {
      color: #ff3300;
    }
  }
  .name {
    margin-bottom: 0.4rem;
    font-size: 0.32rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #767676;
    .list {
      display: flex;
      flex-wrap: wrap;
      .item {
        margin-right: 0.8rem;
      }
    }
  }
}
</style>